<template>
  <!-- 生育保险-->
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <round-container>
          <action-select-field
            name="医院等级"
            v-model="hospital"
            :actions="hospitalActions"></action-select-field>
          <!-- <div class="item-box">
            <van-cell is-link>
              <div class="row-box" @click="hospitalShow = true">
                <title-item text="医院等级"></title-item>
                <span>{{ hospital }}</span>
              </div>
            </van-cell>
          </div> -->
          <van-divider />
          <action-select-field
            name="手术类别"
            v-model="operTypeRes"
            :actions="operActions"></action-select-field>
          <!-- <div class="item-box">
            <van-cell is-link>
              <div class="row-box" @click="operShow = true" :disabled="disabledOperTypeRes">
                <title-item text="手术类别"></title-item>
                <span>{{ operTypeRes }}</span>
              </div>
            </van-cell>
          </div> -->
          <van-divider />
          <action-select-field
            name="生育数量"
            v-model="fetusRes"
            :disabled="disabledOperTypeRes"
            :actions="NFetusActions"></action-select-field>
          <!-- <div class="item-box">
            <van-cell is-link>
              <div class="row-box" @click="NFetusShow = true">
                <title-item text="生育数量"></title-item>
                <span>{{ fetusRes }}</span>
              </div>
            </van-cell>
          </div> -->
          <van-divider />
          <number-input-field name="月缴费基数" v-model="baseFee"></number-input-field>
          <!-- <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="缴费基数"></title-item>
                <van-field
                  readonly
                  clickable
                  :value="baseFee"
                  @touchstart.native.stop="numberShow = true"
                />
                <span class="unit">元</span>
              </div>
            </van-cell>
          </div> -->
          <van-divider />
        </round-container>
      </template>

      <template slot="footerBtn">
        <div
          v-bind:class="['btn-box', 'btn-bk']"
          @click="calc"
        >
          开始计算
        </div>
      </template>
    </calc-page>

    <!-- <van-action-sheet
      title="医院等级"
      v-model="hospitalShow"
      :actions="hospitalActions"
      @select="onHospitalSelect"
    />
    <van-action-sheet
      title="手术类别"
      v-model="operShow"
      :actions="operActions"
      @select="onOperSelect"
    />
    <van-action-sheet
      title="生育数量"
      v-model="NFetusShow"
      :actions="NFetusActions"
      @select="onNFetusSelect"
    />
    <van-number-keyboard
      v-model="baseFee"
      :show="numberShow"
      :maxlength="6"
      @blur="numberShow = false"
    /> -->
  </div> 
</template>

<script>

import CalcPage from "@/components/CalcPage";
// import TitleItem from "@/views/calc/TitleItem";
import RoundContainer from "@/views/calc/components/RoundContainer";
import { getMaternityInsurance } from "@/api/tool";
import to from "@/utils/to";
import ActionSelectField from "@/views/calc/components/ActionSelectField.vue";
import NumberInputField from "../components/NumberInputField.vue";

export default {
  components: {
    CalcPage,
    // TitleItem,
    RoundContainer,
    ActionSelectField,
    NumberInputField,
  },
  data() {
    return {
      // hospitalShow: false,
      // hospital: null,
      hospital: {},
      hospitalActions: [
        { name: "一级医院", value: "1" },
        { name: "二级医院", value: "2" },
        { name: "三级医院", value: "3" },
      ],
      // operShow: false,
      // operTypeRes: null,
      operTypeRes: {},
      operActions: [
        { name: "顺产", value: "1" },
        { name: "难产", value: "2" },
        { name: "剖宫产", value: "3" },
        { name: "宫外孕", value: "4" },
        { name: "4-7个月流产", value: "5" },
        { name: "4个月以下流产", value: "6" },
      ],
      // NFetusShow: false,
      // fetusRes: null,
      fetusRes: {},
      NFetusActions: [
        { name: "单胞胎", value: "1" },
        { name: "双胞胎", value: "2" },
        { name: "三胞胎", value: "3" },
        { name: "四胞胎", value: "4" },
      ],

      // numberShow: false,
      baseFee: "",
      // params: {
      //   hospital: "",
      //   operTypeRes: "",
      //   fetusRes: "",
      //   baseFee: "",
      // },
    };
  },
  computed: {
    disabledSubmitBtn() {
      const name = this.operTypeRes.name || '';
      console.log(name);
      if (name === '宫外孕' || name === '4-7个月流产' || name === '4个月以下流产') {
        return this.baseFee == '' ? true : false
      } else {
        const { hospital,fetusRes,baseFee } = this
        return baseFee == ''
                || hospital.name === undefined
                || fetusRes.name === undefined ? true : false
      }
    },
    disabledOperTypeRes() {
      const name = this.operTypeRes.name || ''
      console.log(name)
      return (name === '宫外孕' || name === '4-7个月流产' || name === '4个月以下流产') ? true : false
    }
  },
  methods: {
    onHospitalSelect(item) {
      this.hospital = item.name;
      // this.params.hospital = item.value;
      // this.hospitalShow = false;
    },
    onOperSelect(item) {
      this.operTypeRes = item.name;
      // this.params.operTypeRes = item.value;
      // this.operShow = false;
    },
    onNFetusSelect(item) {
      this.fetusRes = item.name;
      // this.params.fetusRes = item.value;
      // this.NFetusShow = false;
    },
    async calc() {
      if (this.disabledSubmitBtn) {
          this.$toast.fail("请先填写相关信息");
        return;
      }

      const { hospital,operTypeRes,fetusRes,baseFee } = this
      let params = {
        hospital: hospital.value,
        operTypeRes: operTypeRes.value,
        baseFee
      }

      if (operTypeRes.name === '顺产' || operTypeRes.name === '难产' || operTypeRes.name === '剖宫产') {
        params.fetusRes = fetusRes.value
      }

      /// this.params.baseFee = parseInt(this.baseFee);
      this.$showLoading("加载中...");
      let [err, res] = await to(getMaternityInsurance(params));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("获取失败");
        return;
      }
      this.$router.push({ name: "MaternityResultPage", query: { res: JSON.stringify(res) } });
      // this.$nextTick(()=>{
      //   scroll(0,document.body.scrollHeight)
      // });
    },
  },
};
</script>

<style scoped>
.page >>> .van-divider {
  margin: 0;
}

.item-box {
  display: flex;
  align-items: flex-end;
  padding: 24px 0 12px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  width: 200px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box .unit {
  font-size: 14px;
  font-weight: normal;
  position: relative;
  top: 1px;
}

.row-box >>> .van-cell::after {
  border: none;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}
</style>