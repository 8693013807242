<template>
  <div class="field-box">
    <div class="field">
      <div class="name" :style="{ color: color }">
        <span>{{ name }}</span>
        <img
          v-if="tip"
          src="@/assets/imgs/calc/link.png"
          class="tip"
          @click="$emit('tip')"
        />
      </div>
      <div class="data" @click="show = disabled ? false : true">
        <span>{{ selected.name != undefined ? selected.name : '' }}</span>
        <van-icon name="arrow" size="16" :color="color"/>
      </div>
    </div>
    <van-divider />
    <van-action-sheet
      :title="name"
      v-model="show"
      :actions="actions"
      @select="select"
    />
  </div>
</template>

<script>
import { Divider, Icon, ActionSheet } from "vant";

export default {
  props: {
    name: {
      type: String,
    },
    tip: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [ActionSheet.name]: ActionSheet,
  },
  computed: {
    color() {
      return this.disabled ? '#E6E6E6' : '#808080'
    }
  },
  data() {
    return {
      show: false,
      selected: this.value,
    };
  },
  methods: {
    select(item) {
      this.selected = item;
      this.show = false;
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style scoped>
.field-box >>> .van-divider {
  margin: 0;
}

.field {
  display: flex;
  align-items: center;
  height: 60px;
}

.name {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  display: flex;
  align-items: center;
}

.dis-name {
  color: #E6E6E6;
}

.tip {
  width: 14px;
  height: 14px;
}

.data {
  flex: 1.3;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>